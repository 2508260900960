
// Utilities
import { mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    notifications: [{ href: '/profile', text: 'Lengkapi profile anda' }],
    // title: null,
    responsive: true,
    disableNotification: true,
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
  },
  // watch: {
  //   $route(val) {
  //     this.title =
  //       val.name === 'index'
  //         ? '- dashboard'
  //         : '- ' + val.name.split('-').join(' ')
  //   },
  // },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      this.responsive = window.innerWidth < 991
    },
    logout() {
      this.$swal
        .fire({
          title: 'Kamu yakin akan keluar ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('/api/logout')
              .then((response) => {
                this.$auth.logout()
                this.$swal.fire({
                  type: 'success',
                  title: 'Anda telah berhasil keluar',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.push({ path: '/login' })
              })
              .catch((res) => {
                this.data = null
                this.$auth.logout()
                this.$swal.fire({
                  type: 'warning',
                  title: 'Sesi anda telah berakhir, silahkan masuk kembali',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.push({ path: '/login' })
              })
          }
        })
    },
  },
}
