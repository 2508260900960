
export default {
  components: {
    CoreView: () => import('@/components/core/View'),
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    source: String,
  },
  data: () => ({
    drawer: null,
  }),
  head: {
    bodyAttrs: {
      class: 'hold-transition login-page',
    },
  },
}
