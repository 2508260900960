
// Utilities
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  middleware: 'auth',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeLink: this.$route.path,
      currentSelection: '',
      currentSelectionSub: 0,
      permissions: [],
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
    ...mapState('app', ['image', 'color']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.setDrawer(val)
      },
    },
  },
  mounted() {
    this.permissions = this.loggedInUser.permissions

    for (let i = 0; i < this.permissions.length; i++) {
      if (this.permissions[i].slug === this.$route.path) {
        this.permissions[i].active = true
        this.currentSelection = i
      } else {
        this.permissions[i].active = false
      }
      if (this.permissions[i].permissions.length > 0) {
        for (let j = 0; j < this.permissions[i].permissions.length; j++) {
          if (this.permissions[i].permissions[j].slug === this.$route.path) {
            this.permissions[i].permissions[j].active = true
            this.currentSelectionSub = i.toString() + j.toString()
          } else {
            this.permissions[i].permissions[j].active = false
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    toogleItem(index) {
      this.currentSelection = index
      if (this.permissions[index].permissions.length === 0) {
        this.currentSelectionSub = 0
      }
    },
    toogleSubItem(childIndex) {
      this.currentSelectionSub = childIndex
    },
  },
}
