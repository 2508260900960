
export default {
  name: 'MaterialCardTable',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      default: '',
    },
    showjumlahbukubesar: {
      type: Boolean,
      default: false,
    },
    showjumlahneraca: {
      type: Boolean,
      default: false,
    },
    activetextbutton: {
      type: String,
      default: null,
    },
    showcreatewithidbutton: {
      type: Boolean,
      default: false,
    },
    urlcreate: {
      type: String,
      default: '/',
    },
    urlexport: {
      type: String,
      default: '/',
    },
    urlupload: {
      type: String,
      default: '/',
    },
    urlimport: {
      type: String,
      default: '/',
    },
    urlsubdata: {
      type: String,
      default: '/',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
      default: undefined,
    },
    customtitleleft: {
      type: String,
      default: undefined,
    },
    customtitleright: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    fields: {
      type: Array,
      default: null,
    },
    fieldsdetail: {
      type: Array,
      default: null,
    },
    subdatatext: {
      type: String,
      default: null,
    },
    showprint: {
      type: Boolean,
      default: false,
    },
    showsubdata: {
      type: Boolean,
      default: false,
    },
    showaction: {
      type: Boolean,
      default: false,
    },
    showcreatebutton: {
      type: Boolean,
      default: false,
    },
    showeditbutton: {
      type: Boolean,
      default: false,
    },
    showdeletebutton: {
      type: Boolean,
      default: false,
    },
    showactivebutton: {
      type: Boolean,
      default: false,
    },
    isfiltered: {
      type: Boolean,
      default: false,
    },
    isuploaded: {
      type: Boolean,
      default: false,
    },
    isexported: {
      type: Boolean,
      default: false,
    },
    showpagination: {
      type: Boolean,
      default: false,
    },
    perpage: {
      type: Number,
      default: 10,
    },
    currentpage: {
      type: Number,
      default: 1,
    },
    filters: {
      type: Boolean,
      default: false,
    },
    filterdatas: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      search: '',
      items: [],
      total_debit: 0,
      total_kredit: 0,
      saldo_awal: 0,
      saldo_akhir: 0,
      total_saldo_awal: 0,
      total_saldo_akhir: 0,
    }
  },
  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null
      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    printData() {
      const w = window.open()
      w.document.write(this.$refs.PrintData.$el.innerHTML)
      w.document.close()
      w.setTimeout(function () {
        w.print()
      }, 1000)
    },
    async ChangeStatus(id, isActive) {
      await this.$swal
        .fire({
          title: (isActive ? 'non-aktifkan' : 'aktifkan') + ' data ini ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.url + '/change-status/' + id, {
                is_active: isActive,
              })
              .then((res) => {
                this.$swal
                  .fire({
                    type: 'success',
                    title:
                      'Data berhasil di ' +
                      (isActive ? 'non-aktifkan' : 'aktifkan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  type: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    searchData() {
      this.getData(this.search)
    },
    async getData(q) {
      let query = '?'
      if (this.filterdatas !== null) {
        if (this.filterdatas.account_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'account_id=' + this.filterdatas.account_id
        }
        if (this.filterdatas.dari !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'dari=' + this.filterdatas.dari
        }
        if (this.filterdatas.sampai !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'sampai=' + this.filterdatas.sampai
        }
        if (this.filterdatas.institution_id !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'institution_id=' + this.filterdatas.institution_id
        }
        if (this.filterdatas.paid !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'paid=' + this.filterdatas.paid
        }
        if (this.filterdatas.type !== undefined) {
          if (query !== '') {
            query += '&'
          }
          query += 'type=' + this.filterdatas.type
        }
      } else {
        query += q ? 'q=' + q : ''
      }

      await this.$axios
        .get('api/' + this.url + (query !== '' ? query : ''))
        .then((res) => {
          this.items = res.data.data
          if (this.items !== null) {
            if (this.showjumlahbukubesar) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.saldo_awal = res.data.saldo_awal
              this.saldo_akhir = res.data.saldo_akhir
            }
            if (this.showjumlahneraca) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.total_saldo_awal = res.data.total_saldo_awal
              this.total_saldo_akhir = res.data.total_saldo_akhir
            }
            setTimeout(function () {
              this.isBusy = true
            }, 5000)
          } else {
            this.items = []
          }
        })
      // .catch((res) => {
      //   this.$swal.fire({
      //     type: 'warning',
      //     title: res.response.data.message,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   })
      // })
    },
    async deleteData(id) {
      await this.$swal
        .fire({
          title: 'hapus data ini ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios.delete('api/' + this.url + '/' + id).then((res) => {
              this.$swal
                .fire({
                  type: 'success',
                  title: 'Data berhasil di hapus',
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then((res) => {
                  this.getData()
                })
            })
          }
        })
    },
  },
}
