import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f1d4c7f = () => interopDefault(import('../pages/login-history/index.vue' /* webpackChunkName: "pages/login-history/index" */))
const _3f666890 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _119ebc10 = () => interopDefault(import('../pages/read-more.vue' /* webpackChunkName: "pages/read-more" */))
const _6fc2a31c = () => interopDefault(import('../pages/santri/index.vue' /* webpackChunkName: "pages/santri/index" */))
const _03930dfa = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _5483afb4 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _3a1336f6 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _c77ee3c2 = () => interopDefault(import('../pages/login-history/create.vue' /* webpackChunkName: "pages/login-history/create" */))
const _5fe33068 = () => interopDefault(import('../pages/santri/profil.vue' /* webpackChunkName: "pages/santri/profil" */))
const _5ca50c8d = () => interopDefault(import('../pages/admin/core/akun-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/index" */))
const _309812b0 = () => interopDefault(import('../pages/admin/core/buku-besar/index.vue' /* webpackChunkName: "pages/admin/core/buku-besar/index" */))
const _7cb17e4b = () => interopDefault(import('../pages/admin/core/jurnal-khusus/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-khusus/index" */))
const _e7232300 = () => interopDefault(import('../pages/admin/core/jurnal-penyesuaian/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-penyesuaian/index" */))
const _ae486c3c = () => interopDefault(import('../pages/admin/core/jurnal-umum/index.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/index" */))
const _5635604f = () => interopDefault(import('../pages/admin/core/kertas-kerja/index.vue' /* webpackChunkName: "pages/admin/core/kertas-kerja/index" */))
const _0259f055 = () => interopDefault(import('../pages/admin/core/laba-rugi/index.vue' /* webpackChunkName: "pages/admin/core/laba-rugi/index" */))
const _5be6d3ad = () => interopDefault(import('../pages/admin/core/laporan-keuangan/index.vue' /* webpackChunkName: "pages/admin/core/laporan-keuangan/index" */))
const _14b73603 = () => interopDefault(import('../pages/admin/core/neraca/index.vue' /* webpackChunkName: "pages/admin/core/neraca/index" */))
const _5cf6ee46 = () => interopDefault(import('../pages/admin/customer-service/nasabah/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/index" */))
const _96ae589a = () => interopDefault(import('../pages/admin/customer-service/registrasi/index.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/index" */))
const _1b6c8306 = () => interopDefault(import('../pages/admin/customer-service/transaksi/index.vue' /* webpackChunkName: "pages/admin/customer-service/transaksi/index" */))
const _53d49202 = () => interopDefault(import('../pages/admin/customer-service/wali/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/index" */))
const _50d3d7d0 = () => interopDefault(import('../pages/admin/master/bank/index.vue' /* webpackChunkName: "pages/admin/master/bank/index" */))
const _bc948dd0 = () => interopDefault(import('../pages/admin/master/bcn-bank/index.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/index" */))
const _8d91ffb4 = () => interopDefault(import('../pages/admin/master/document-type/index.vue' /* webpackChunkName: "pages/admin/master/document-type/index" */))
const _73e504c7 = () => interopDefault(import('../pages/admin/master/jenis-kas/index.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/index" */))
const _1a6dc98c = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/index.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/index" */))
const _397b3fa6 = () => interopDefault(import('../pages/admin/master/jenis-rekening/index.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/index" */))
const _689467bf = () => interopDefault(import('../pages/admin/master/jenis-simpanan/index.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/index" */))
const _15589ae7 = () => interopDefault(import('../pages/admin/master/lembaga/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/index" */))
const _146c07ef = () => interopDefault(import('../pages/admin/master/news/index.vue' /* webpackChunkName: "pages/admin/master/news/index" */))
const _3aca3eee = () => interopDefault(import('../pages/admin/master/produk/index.vue' /* webpackChunkName: "pages/admin/master/produk/index" */))
const _7e9d60e5 = () => interopDefault(import('../pages/admin/master/transaction-type/index.vue' /* webpackChunkName: "pages/admin/master/transaction-type/index" */))
const _6a0b77d0 = () => interopDefault(import('../pages/admin/pengaturan/management-user/index.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/index" */))
const _9cd25dfc = () => interopDefault(import('../pages/admin/pengaturan/permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/index" */))
const _b06cae2e = () => interopDefault(import('../pages/admin/pengaturan/role/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role/index" */))
const _e2a17dc8 = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/index.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/index" */))
const _928bc17e = () => interopDefault(import('../pages/admin/transaksi/penarikan/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/index" */))
const _31773642 = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/index.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/index" */))
const _4c32c2c2 = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/index.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/index" */))
const _5fb0cfd1 = () => interopDefault(import('../pages/admin/core/akun-keuangan/create.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/create" */))
const _cb5c85c8 = () => interopDefault(import('../pages/admin/core/jurnal-umum/create.vue' /* webpackChunkName: "pages/admin/core/jurnal-umum/create" */))
const _699b2538 = () => interopDefault(import('../pages/admin/customer-service/nasabah/create.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/create" */))
const _24767bf0 = () => interopDefault(import('../pages/admin/customer-service/nasabah/export.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/export" */))
const _34f7ea80 = () => interopDefault(import('../pages/admin/customer-service/nasabah/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/rekening/index" */))
const _098187bd = () => interopDefault(import('../pages/admin/customer-service/nasabah/upload.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/upload" */))
const _0825ed6b = () => interopDefault(import('../pages/admin/customer-service/registrasi/create.vue' /* webpackChunkName: "pages/admin/customer-service/registrasi/create" */))
const _1455739f = () => interopDefault(import('../pages/admin/customer-service/wali/create.vue' /* webpackChunkName: "pages/admin/customer-service/wali/create" */))
const _070ce1e7 = () => interopDefault(import('../pages/admin/customer-service/wali/rekening/index.vue' /* webpackChunkName: "pages/admin/customer-service/wali/rekening/index" */))
const _42e0b9a6 = () => interopDefault(import('../pages/admin/master/bank/create.vue' /* webpackChunkName: "pages/admin/master/bank/create" */))
const _869496b4 = () => interopDefault(import('../pages/admin/master/bcn-bank/create.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/create" */))
const _d5456150 = () => interopDefault(import('../pages/admin/master/document-type/create.vue' /* webpackChunkName: "pages/admin/master/document-type/create" */))
const _306fded7 = () => interopDefault(import('../pages/admin/master/jenis-kas/create.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/create" */))
const _e3e2d278 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/create.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/create" */))
const _a6841f9e = () => interopDefault(import('../pages/admin/master/jenis-rekening/create.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/create" */))
const _5ca64a42 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/create.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/create" */))
const _5c05fb2a = () => interopDefault(import('../pages/admin/master/lembaga/bcn-code/index.vue' /* webpackChunkName: "pages/admin/master/lembaga/bcn-code/index" */))
const _8521e692 = () => interopDefault(import('../pages/admin/master/lembaga/create.vue' /* webpackChunkName: "pages/admin/master/lembaga/create" */))
const _be6d7ea2 = () => interopDefault(import('../pages/admin/master/news/create.vue' /* webpackChunkName: "pages/admin/master/news/create" */))
const _cf150956 = () => interopDefault(import('../pages/admin/master/produk/create.vue' /* webpackChunkName: "pages/admin/master/produk/create" */))
const _7cc30679 = () => interopDefault(import('../pages/admin/master/transaction-type/create.vue' /* webpackChunkName: "pages/admin/master/transaction-type/create" */))
const _87faecb4 = () => interopDefault(import('../pages/admin/pengaturan/management-user/create.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/create" */))
const _ae10cc08 = () => interopDefault(import('../pages/admin/pengaturan/permission/create.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/create" */))
const _791fbef5 = () => interopDefault(import('../pages/admin/pengaturan/role/create.vue' /* webpackChunkName: "pages/admin/pengaturan/role/create" */))
const _2523dcbc = () => interopDefault(import('../pages/admin/transaksi/penarikan-otomatis/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan-otomatis/create" */))
const _483e139d = () => interopDefault(import('../pages/admin/transaksi/penarikan/create.vue' /* webpackChunkName: "pages/admin/transaksi/penarikan/create" */))
const _61d9e03c = () => interopDefault(import('../pages/admin/transaksi/setoran-tunai/create.vue' /* webpackChunkName: "pages/admin/transaksi/setoran-tunai/create" */))
const _1f36ce35 = () => interopDefault(import('../pages/admin/core/akun-keuangan/_id.vue' /* webpackChunkName: "pages/admin/core/akun-keuangan/_id" */))
const _78506ba4 = () => interopDefault(import('../pages/admin/customer-service/nasabah/_id.vue' /* webpackChunkName: "pages/admin/customer-service/nasabah/_id" */))
const _a55925b2 = () => interopDefault(import('../pages/admin/customer-service/wali/_id.vue' /* webpackChunkName: "pages/admin/customer-service/wali/_id" */))
const _5d6b8700 = () => interopDefault(import('../pages/admin/master/bank/_id.vue' /* webpackChunkName: "pages/admin/master/bank/_id" */))
const _790f2180 = () => interopDefault(import('../pages/admin/master/bcn-bank/_id.vue' /* webpackChunkName: "pages/admin/master/bcn-bank/_id" */))
const _6ab2d40e = () => interopDefault(import('../pages/admin/master/document-type/_id.vue' /* webpackChunkName: "pages/admin/master/document-type/_id" */))
const _7ea8e622 = () => interopDefault(import('../pages/admin/master/jenis-kas/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-kas/_id" */))
const _7e78a422 = () => interopDefault(import('../pages/admin/master/jenis-pinjaman/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-pinjaman/_id" */))
const _1356ec56 = () => interopDefault(import('../pages/admin/master/jenis-rekening/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-rekening/_id" */))
const _6d474de7 = () => interopDefault(import('../pages/admin/master/jenis-simpanan/_id.vue' /* webpackChunkName: "pages/admin/master/jenis-simpanan/_id" */))
const _7f63a9e2 = () => interopDefault(import('../pages/admin/master/lembaga/_id.vue' /* webpackChunkName: "pages/admin/master/lembaga/_id" */))
const _8c2d8bd2 = () => interopDefault(import('../pages/admin/master/news/_id.vue' /* webpackChunkName: "pages/admin/master/news/_id" */))
const _5f497131 = () => interopDefault(import('../pages/admin/master/produk/_id.vue' /* webpackChunkName: "pages/admin/master/produk/_id" */))
const _f12f4ee6 = () => interopDefault(import('../pages/admin/master/transaction-type/_id.vue' /* webpackChunkName: "pages/admin/master/transaction-type/_id" */))
const _a00b2700 = () => interopDefault(import('../pages/admin/pengaturan/management-user/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/management-user/_id" */))
const _2f492bea = () => interopDefault(import('../pages/admin/pengaturan/permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/permission/_id" */))
const _1812da84 = () => interopDefault(import('../pages/admin/pengaturan/role-has-permission/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role-has-permission/_id" */))
const _504ed191 = () => interopDefault(import('../pages/admin/pengaturan/role/_id.vue' /* webpackChunkName: "pages/admin/pengaturan/role/_id" */))
const _6211b806 = () => interopDefault(import('../pages/auth/_registerBak.vue' /* webpackChunkName: "pages/auth/_registerBak" */))
const _737b3ab2 = () => interopDefault(import('../pages/login-history/_id.vue' /* webpackChunkName: "pages/login-history/_id" */))
const _bbb19ec4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login-history",
    component: _6f1d4c7f,
    name: "login-history"
  }, {
    path: "/profile",
    component: _3f666890,
    name: "profile"
  }, {
    path: "/read-more",
    component: _119ebc10,
    name: "read-more"
  }, {
    path: "/santri",
    component: _6fc2a31c,
    name: "santri"
  }, {
    path: "/admin/home",
    component: _03930dfa,
    name: "admin-home"
  }, {
    path: "/auth/login",
    component: _5483afb4,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _3a1336f6,
    name: "auth-register"
  }, {
    path: "/login-history/create",
    component: _c77ee3c2,
    name: "login-history-create"
  }, {
    path: "/santri/profil",
    component: _5fe33068,
    name: "santri-profil"
  }, {
    path: "/admin/core/akun-keuangan",
    component: _5ca50c8d,
    name: "admin-core-akun-keuangan"
  }, {
    path: "/admin/core/buku-besar",
    component: _309812b0,
    name: "admin-core-buku-besar"
  }, {
    path: "/admin/core/jurnal-khusus",
    component: _7cb17e4b,
    name: "admin-core-jurnal-khusus"
  }, {
    path: "/admin/core/jurnal-penyesuaian",
    component: _e7232300,
    name: "admin-core-jurnal-penyesuaian"
  }, {
    path: "/admin/core/jurnal-umum",
    component: _ae486c3c,
    name: "admin-core-jurnal-umum"
  }, {
    path: "/admin/core/kertas-kerja",
    component: _5635604f,
    name: "admin-core-kertas-kerja"
  }, {
    path: "/admin/core/laba-rugi",
    component: _0259f055,
    name: "admin-core-laba-rugi"
  }, {
    path: "/admin/core/laporan-keuangan",
    component: _5be6d3ad,
    name: "admin-core-laporan-keuangan"
  }, {
    path: "/admin/core/neraca",
    component: _14b73603,
    name: "admin-core-neraca"
  }, {
    path: "/admin/customer-service/nasabah",
    component: _5cf6ee46,
    name: "admin-customer-service-nasabah"
  }, {
    path: "/admin/customer-service/registrasi",
    component: _96ae589a,
    name: "admin-customer-service-registrasi"
  }, {
    path: "/admin/customer-service/transaksi",
    component: _1b6c8306,
    name: "admin-customer-service-transaksi"
  }, {
    path: "/admin/customer-service/wali",
    component: _53d49202,
    name: "admin-customer-service-wali"
  }, {
    path: "/admin/master/bank",
    component: _50d3d7d0,
    name: "admin-master-bank"
  }, {
    path: "/admin/master/bcn-bank",
    component: _bc948dd0,
    name: "admin-master-bcn-bank"
  }, {
    path: "/admin/master/document-type",
    component: _8d91ffb4,
    name: "admin-master-document-type"
  }, {
    path: "/admin/master/jenis-kas",
    component: _73e504c7,
    name: "admin-master-jenis-kas"
  }, {
    path: "/admin/master/jenis-pinjaman",
    component: _1a6dc98c,
    name: "admin-master-jenis-pinjaman"
  }, {
    path: "/admin/master/jenis-rekening",
    component: _397b3fa6,
    name: "admin-master-jenis-rekening"
  }, {
    path: "/admin/master/jenis-simpanan",
    component: _689467bf,
    name: "admin-master-jenis-simpanan"
  }, {
    path: "/admin/master/lembaga",
    component: _15589ae7,
    name: "admin-master-lembaga"
  }, {
    path: "/admin/master/news",
    component: _146c07ef,
    name: "admin-master-news"
  }, {
    path: "/admin/master/produk",
    component: _3aca3eee,
    name: "admin-master-produk"
  }, {
    path: "/admin/master/transaction-type",
    component: _7e9d60e5,
    name: "admin-master-transaction-type"
  }, {
    path: "/admin/pengaturan/management-user",
    component: _6a0b77d0,
    name: "admin-pengaturan-management-user"
  }, {
    path: "/admin/pengaturan/permission",
    component: _9cd25dfc,
    name: "admin-pengaturan-permission"
  }, {
    path: "/admin/pengaturan/role",
    component: _b06cae2e,
    name: "admin-pengaturan-role"
  }, {
    path: "/admin/pengaturan/role-has-permission",
    component: _e2a17dc8,
    name: "admin-pengaturan-role-has-permission"
  }, {
    path: "/admin/transaksi/penarikan",
    component: _928bc17e,
    name: "admin-transaksi-penarikan"
  }, {
    path: "/admin/transaksi/penarikan-otomatis",
    component: _31773642,
    name: "admin-transaksi-penarikan-otomatis"
  }, {
    path: "/admin/transaksi/setoran-tunai",
    component: _4c32c2c2,
    name: "admin-transaksi-setoran-tunai"
  }, {
    path: "/admin/core/akun-keuangan/create",
    component: _5fb0cfd1,
    name: "admin-core-akun-keuangan-create"
  }, {
    path: "/admin/core/jurnal-umum/create",
    component: _cb5c85c8,
    name: "admin-core-jurnal-umum-create"
  }, {
    path: "/admin/customer-service/nasabah/create",
    component: _699b2538,
    name: "admin-customer-service-nasabah-create"
  }, {
    path: "/admin/customer-service/nasabah/export",
    component: _24767bf0,
    name: "admin-customer-service-nasabah-export"
  }, {
    path: "/admin/customer-service/nasabah/rekening",
    component: _34f7ea80,
    name: "admin-customer-service-nasabah-rekening"
  }, {
    path: "/admin/customer-service/nasabah/upload",
    component: _098187bd,
    name: "admin-customer-service-nasabah-upload"
  }, {
    path: "/admin/customer-service/registrasi/create",
    component: _0825ed6b,
    name: "admin-customer-service-registrasi-create"
  }, {
    path: "/admin/customer-service/wali/create",
    component: _1455739f,
    name: "admin-customer-service-wali-create"
  }, {
    path: "/admin/customer-service/wali/rekening",
    component: _070ce1e7,
    name: "admin-customer-service-wali-rekening"
  }, {
    path: "/admin/master/bank/create",
    component: _42e0b9a6,
    name: "admin-master-bank-create"
  }, {
    path: "/admin/master/bcn-bank/create",
    component: _869496b4,
    name: "admin-master-bcn-bank-create"
  }, {
    path: "/admin/master/document-type/create",
    component: _d5456150,
    name: "admin-master-document-type-create"
  }, {
    path: "/admin/master/jenis-kas/create",
    component: _306fded7,
    name: "admin-master-jenis-kas-create"
  }, {
    path: "/admin/master/jenis-pinjaman/create",
    component: _e3e2d278,
    name: "admin-master-jenis-pinjaman-create"
  }, {
    path: "/admin/master/jenis-rekening/create",
    component: _a6841f9e,
    name: "admin-master-jenis-rekening-create"
  }, {
    path: "/admin/master/jenis-simpanan/create",
    component: _5ca64a42,
    name: "admin-master-jenis-simpanan-create"
  }, {
    path: "/admin/master/lembaga/bcn-code",
    component: _5c05fb2a,
    name: "admin-master-lembaga-bcn-code"
  }, {
    path: "/admin/master/lembaga/create",
    component: _8521e692,
    name: "admin-master-lembaga-create"
  }, {
    path: "/admin/master/news/create",
    component: _be6d7ea2,
    name: "admin-master-news-create"
  }, {
    path: "/admin/master/produk/create",
    component: _cf150956,
    name: "admin-master-produk-create"
  }, {
    path: "/admin/master/transaction-type/create",
    component: _7cc30679,
    name: "admin-master-transaction-type-create"
  }, {
    path: "/admin/pengaturan/management-user/create",
    component: _87faecb4,
    name: "admin-pengaturan-management-user-create"
  }, {
    path: "/admin/pengaturan/permission/create",
    component: _ae10cc08,
    name: "admin-pengaturan-permission-create"
  }, {
    path: "/admin/pengaturan/role/create",
    component: _791fbef5,
    name: "admin-pengaturan-role-create"
  }, {
    path: "/admin/transaksi/penarikan-otomatis/create",
    component: _2523dcbc,
    name: "admin-transaksi-penarikan-otomatis-create"
  }, {
    path: "/admin/transaksi/penarikan/create",
    component: _483e139d,
    name: "admin-transaksi-penarikan-create"
  }, {
    path: "/admin/transaksi/setoran-tunai/create",
    component: _61d9e03c,
    name: "admin-transaksi-setoran-tunai-create"
  }, {
    path: "/admin/core/akun-keuangan/:id?",
    component: _1f36ce35,
    name: "admin-core-akun-keuangan-id"
  }, {
    path: "/admin/customer-service/nasabah/:id?",
    component: _78506ba4,
    name: "admin-customer-service-nasabah-id"
  }, {
    path: "/admin/customer-service/wali/:id?",
    component: _a55925b2,
    name: "admin-customer-service-wali-id"
  }, {
    path: "/admin/master/bank/:id",
    component: _5d6b8700,
    name: "admin-master-bank-id"
  }, {
    path: "/admin/master/bcn-bank/:id?",
    component: _790f2180,
    name: "admin-master-bcn-bank-id"
  }, {
    path: "/admin/master/document-type/:id?",
    component: _6ab2d40e,
    name: "admin-master-document-type-id"
  }, {
    path: "/admin/master/jenis-kas/:id?",
    component: _7ea8e622,
    name: "admin-master-jenis-kas-id"
  }, {
    path: "/admin/master/jenis-pinjaman/:id?",
    component: _7e78a422,
    name: "admin-master-jenis-pinjaman-id"
  }, {
    path: "/admin/master/jenis-rekening/:id?",
    component: _1356ec56,
    name: "admin-master-jenis-rekening-id"
  }, {
    path: "/admin/master/jenis-simpanan/:id?",
    component: _6d474de7,
    name: "admin-master-jenis-simpanan-id"
  }, {
    path: "/admin/master/lembaga/:id",
    component: _7f63a9e2,
    name: "admin-master-lembaga-id"
  }, {
    path: "/admin/master/news/:id",
    component: _8c2d8bd2,
    name: "admin-master-news-id"
  }, {
    path: "/admin/master/produk/:id",
    component: _5f497131,
    name: "admin-master-produk-id"
  }, {
    path: "/admin/master/transaction-type/:id?",
    component: _f12f4ee6,
    name: "admin-master-transaction-type-id"
  }, {
    path: "/admin/pengaturan/management-user/:id?",
    component: _a00b2700,
    name: "admin-pengaturan-management-user-id"
  }, {
    path: "/admin/pengaturan/permission/:id",
    component: _2f492bea,
    name: "admin-pengaturan-permission-id"
  }, {
    path: "/admin/pengaturan/role-has-permission/:id",
    component: _1812da84,
    name: "admin-pengaturan-role-has-permission-id"
  }, {
    path: "/admin/pengaturan/role/:id",
    component: _504ed191,
    name: "admin-pengaturan-role-id"
  }, {
    path: "/auth/:registerBak?",
    component: _6211b806,
    name: "auth-registerBak"
  }, {
    path: "/login-history/:id?",
    component: _737b3ab2,
    name: "login-history-id"
  }, {
    path: "/",
    component: _bbb19ec4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
